import { render, staticRenderFns } from "./BaseHeader.vue?vue&type=template&id=771e3561&scoped=true&"
import script from "./BaseHeader.vue?vue&type=script&lang=ts&"
export * from "./BaseHeader.vue?vue&type=script&lang=ts&"
import style0 from "./BaseHeader.vue?vue&type=style&index=0&id=771e3561&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "771e3561",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ThreesidesLogo: require('/codebuild/output/src2096755600/src/threease_lp/components/logo/ThreesidesLogo.vue').default,NavList: require('/codebuild/output/src2096755600/src/threease_lp/components/NavList.vue').default,BaseButton: require('/codebuild/output/src2096755600/src/threease_lp/components/shared/Base/BaseButton.vue').default,SpHeaderMenu: require('/codebuild/output/src2096755600/src/threease_lp/components/shared/Base/header/SpHeaderMenu.vue').default,BaseContainer: require('/codebuild/output/src2096755600/src/threease_lp/components/shared/Base/BaseContainer.vue').default})
